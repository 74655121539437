<template>
    <div class="like">
        <button v-if="!cart" @click="addToCart">
            <div><img src="/images/sub/collection/h1.png" alt=""></div>
            <span>찜하기</span>
        </button>
        <button v-else @click="cancel">
            <div><img src="/images/sub/collection/h2.png" alt=""></div>
            <span>찜하기</span>
        </button>
    </div>
</template>
<script>
export default{
    props: {
        product: { type: Object, default: undefined }
    },
    data: () => {
        return {
            cart: null
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                if(this.accessToken) this.cart = await this.getCart();
            }
            catch(error){
                console.error(error);
                if(error.response.data.message) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        async addToCart(){
            try{
                if(!this.accessToken){
                    this.$router.push("/login");
                    return;
                }

                var { carts } = await this.postCart({
                    carts: [{
    					_product: this.product._id,
    					product: this.product,
    					name: "본품",
    					price: this.product.price,
    					salePrice: this.product.salePrice,
    					discountPrice: this.product.discountPrice,
    					stock: this.product.stock,
    					amount: 1,
    				}]
                });

                this.$store.dispatch("addToCart", carts);
                this.cart = carts[0];
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        async cancel(){
            try{
                await this.deleteCart(this.cart);

                this.$store.dispatch("removeCart", this.cart);
                this.cart = null;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        getCart(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/v1/me/carts/${this.product._id}`);

                    if(this.cart) this.$store.dispatch("addToCart", [ this.cart ]);

                    resolve(res.data.cart);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        postCart(data){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post("/api/v1/me/carts", data);

                    resolve(res.data);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        deleteCart(data){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.delete(`/api/v1/me/carts/${data._id}`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        }
    },
    watch: {
        accessToken(){
            if(this.accessToken) this.getCart().then((cart) => this.cart = cart);
        },
        product(){
            if(this.accessToken) this.getCart().then((cart) => this.cart = cart);
        }
    }
}
</script>
