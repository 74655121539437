<template>
	<div class="wrap wrap--sub">
		<!-- 헤더 -->
		<main-header></main-header>

		<!-- 메인 -->
	    <main class="main" role="main">
            <div class="collection-index-container">
                <div class="page-head">
                    <div class="page-head__inner">
                        <h1 class="page-head__title">COLLECTION</h1>
                        <ul class="breadcrumb">
                            <li class="breadcrumb__list">디아이디의 샘플북을 확인해보세요</li>
                            <!-- <li class="breadcrumb__list">컬렉션</li>
                            <li class="breadcrumb__list">국내</li>
                            <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li> -->
                        </ul>
                    </div>
                </div>

                <div class="container">
                    <div class="nine-btn-wrap nine-btn-wrap--li">
                        <router-link to="/center/notice?_notification=658000b6f265e33f0402f5fa" target="_blank" class="nine-btn">나인벽지 취급점 안내</router-link>
                    </div>
                    <div class="collection-list-subtitle-wrap collection-list-subtitle-wrap--first">
                        <div class="box-icon orange"></div>
                        <h3 class="collection-list-subtitle-header">내수용</h3>
                        <div class="line"></div>
                    </div>
                    <div class="container2">
                        <collection-list code="collection" type="내수용"></collection-list>
                    </div>

                    <div class="collection-list-subtitle-wrap">
                        <div class="box-icon orange"></div>
                        <h3 class="collection-list-subtitle-header">수출용</h3>
                        <div class="line"></div>
                    </div>
                    <div class="container2">
                        <collection-list code="collection" type="수출용"></collection-list>
                    </div>
                    <div class="mt-40 mt-md-20 align-center">
                        <a href="https://en.didwallpaper.com/collection" target="_blank" class="button button--md button--round button--point">수출용 컬렉션 둘러보기</a>
                    </div>

                    <div class="collection-text">
                        <p>“ 논현동 디아이디 패밀리샵에는 3권 외에, <strong class="color-point">세계적으로 인정받는 2,000종 이상의 수출벽지가 비치</strong>되어 있고, <strong class="color-point">실제 집처럼 크게 도배</strong>되어 있습니다. ”</p>
                        <small>(현장에서 바로 구매 가능)</small>
                    </div>
                </div>
            </div>
         </main>

        <!-- 푸터 -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import MainHeader from '@/components/client/inc/main-header.vue';
import MainFooter from '@/components/client/inc/main-footer.vue';

import CollectionList from "@/components/client/collection/collection-list.vue";

export default{
	components: {
		MainHeader,
		MainFooter,
		CollectionList
	},
	created() {
	},
	mounted() {
		// this.init();
	},
}
</script>
