var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header'), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "collection-index-container"
  }, [_vm._m(0), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "nine-btn-wrap nine-btn-wrap--li"
  }, [_c('router-link', {
    staticClass: "nine-btn",
    attrs: {
      "to": "/center/notice?_notification=658000b6f265e33f0402f5fa",
      "target": "_blank"
    }
  }, [_vm._v("나인벽지 취급점 안내")])], 1), _vm._m(1), _c('div', {
    staticClass: "container2"
  }, [_c('collection-list', {
    attrs: {
      "code": "collection",
      "type": "내수용"
    }
  })], 1), _vm._m(2), _c('div', {
    staticClass: "container2"
  }, [_c('collection-list', {
    attrs: {
      "code": "collection",
      "type": "수출용"
    }
  })], 1), _vm._m(3), _vm._m(4)])])]), _c('main-footer')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner"
  }, [_c('h1', {
    staticClass: "page-head__title"
  }, [_vm._v("COLLECTION")]), _c('ul', {
    staticClass: "breadcrumb"
  }, [_c('li', {
    staticClass: "breadcrumb__list"
  }, [_vm._v("디아이디의 샘플북을 확인해보세요")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "collection-list-subtitle-wrap collection-list-subtitle-wrap--first"
  }, [_c('div', {
    staticClass: "box-icon orange"
  }), _c('h3', {
    staticClass: "collection-list-subtitle-header"
  }, [_vm._v("내수용")]), _c('div', {
    staticClass: "line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "collection-list-subtitle-wrap"
  }, [_c('div', {
    staticClass: "box-icon orange"
  }), _c('h3', {
    staticClass: "collection-list-subtitle-header"
  }, [_vm._v("수출용")]), _c('div', {
    staticClass: "line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-40 mt-md-20 align-center"
  }, [_c('a', {
    staticClass: "button button--md button--round button--point",
    attrs: {
      "href": "https://en.didwallpaper.com/collection",
      "target": "_blank"
    }
  }, [_vm._v("수출용 컬렉션 둘러보기")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "collection-text"
  }, [_c('p', [_vm._v("“ 논현동 디아이디 패밀리샵에는 3권 외에, "), _c('strong', {
    staticClass: "color-point"
  }, [_vm._v("세계적으로 인정받는 2,000종 이상의 수출벽지가 비치")]), _vm._v("되어 있고, "), _c('strong', {
    staticClass: "color-point"
  }, [_vm._v("실제 집처럼 크게 도배")]), _vm._v("되어 있습니다. ”")]), _c('small', [_vm._v("(현장에서 바로 구매 가능)")])]);

}]

export { render, staticRenderFns }