var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('div', {
    staticClass: "wrap wrap--sub"
  }, [_c('main-header', {
    attrs: {
      "val": _vm.val
    }
  }), _c('main', {
    staticClass: "main",
    attrs: {
      "role": "main"
    }
  }, [_c('div', {
    staticClass: "collection-index-container"
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner page-head__img-wrap"
  }, [_c('div', {
    staticClass: "page-head__img",
    style: {
      backgroundImage: `url('${_vm.collection.thumb}')`
    }
  }), _c('div', {
    staticClass: "page-head__con"
  }, [_c('h1', {
    staticClass: "page-head__title2"
  }, [_vm._v(_vm._s(_vm.collection.name))]), _c('p', {
    staticClass: "page-head__text",
    domProps: {
      "innerHTML": _vm._s(_vm.collection.summary.replace(/\n/g, '<br>'))
    }
  })])])]), _c('div', {
    staticClass: "container"
  }, [_vm.collection.name === '나인' ? _c('div', {
    staticClass: "nine-btn-wrap nine-btn-wrap--index"
  }, [_c('router-link', {
    staticClass: "nine-btn",
    attrs: {
      "to": "/center/notice?_notification=658000b6f265e33f0402f5fa",
      "target": "_blank"
    }
  }, [_vm._v("나인벽지 취급점 안내")])], 1) : _vm._e(), _c('div', {
    staticClass: "index-container"
  }, [_c('div', {
    staticClass: "index-wrapper"
  }, _vm._l(_vm.collection.children, function (texture) {
    return _c('div', {
      staticClass: "index-items"
    }, [_c('div', {
      staticClass: "index-slide",
      class: {
        'index-slide--selected': _vm.$data.texture == texture
      },
      attrs: {
        "role": "button"
      },
      on: {
        "click": function ($event) {
          _vm.$data.texture = texture;
          _vm.$data.textureSticky = texture;
        }
      }
    }, [_c('span', {
      staticClass: "thumbnail",
      style: {
        backgroundImage: `url('${texture.thumb}')`
      }
    }), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(texture.name))])])]);
  }), 0)]), _c('div', {
    staticClass: "curtain-slide-container"
  }, _vm._l(_vm.papers, function (paper) {
    return _c('a', {
      staticClass: "curtain-slide",
      class: {
        'curtain-slide--hover': _vm.$data.paper.code == paper.code,
        'curtain-slide--light': paper.fontType == 'light',
        'curtain-slide--shadow': paper.fontType == 'shadow'
      },
      on: {
        "click": function ($event) {
          _vm.$data.paper = paper;
        }
      }
    }, [_c('div', {
      staticClass: "pattern"
    }, [_c('span', {
      staticClass: "background",
      style: {
        backgroundImage: `url('${paper.images[0]}')`,
        backgroundSize: `${720 * (paper.zoomBasic / 100) * 1.3}px !important`
      }
    })]), _c('div', {
      staticClass: "contents"
    }, [_c('span', {
      staticClass: "number"
    }, [_vm._v(_vm._s(paper.code))]), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(paper.name)), _c('small', [_vm._v(_vm._s(paper.nameEn))])])])]);
  }), 0), _vm.paper ? _c('div', {
    staticClass: "collection-view-container",
    attrs: {
      "data-collection": "opened"
    }
  }, [_c('div', {
    staticClass: "wallpaper-container",
    staticStyle: {
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "wallpaper-inner"
  }, [_c('div', {
    staticClass: "wallpaper-pattern"
  }, [_vm._m(0), _c('div', {
    staticClass: "pattern-slide-container"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.collection.children, function (texture) {
    return _c('div', {
      staticClass: "swiper-slide"
    }, [_c('div', {
      staticClass: "pattern-slide",
      class: {
        'pattern-slide--selected': _vm.$data.texture == texture
      },
      attrs: {
        "role": "button"
      },
      on: {
        "click": function ($event) {
          _vm.$data.texture = texture;
        }
      }
    }, [_c('span', {
      staticClass: "thumbnail",
      style: {
        backgroundImage: `url('${texture.thumb}')`
      }
    }), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(texture.name))])])]);
  }), 0)]), _c('div', {
    staticClass: "button-prev"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "17.5px",
      "height": "26.5px"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "stroke": "rgb(255, 255, 255)",
      "stroke-width": "3px",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "fill": "none",
      "d": "M14.500,20.500 L6.500,12.605 L14.500,4.500 "
    }
  })])]), _c('div', {
    staticClass: "button-next"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "width": "17.5px",
      "height": "26.5px"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "stroke": "rgb(255, 255, 255)",
      "stroke-width": "3px",
      "stroke-linecap": "butt",
      "stroke-linejoin": "miter",
      "fill": "none",
      "d": "M1.500,20.500 L9.500,12.500 L1.500,4.500 "
    }
  })])])])]), _c('div', {
    staticClass: "wallpaper-visual"
  }, [_c('div', {
    staticClass: "wallpaper-slide-container"
  }, _vm._l(_vm.papers, function (paper, index) {
    return _c('a', {
      staticClass: "wallpaper-slide",
      class: {
        'wallpaper-slide--hover': index == 0,
        'wallpaper-slide--light': paper.fontType == 'light',
        'wallpaper-slide--shadow': paper.fontType == 'shadow'
      },
      on: {
        "click": function ($event) {
          _vm.$data.paper = paper;
        }
      }
    }, [_c('div', {
      staticClass: "pattern"
    }, [_c('span', {
      staticClass: "background",
      style: {
        backgroundImage: `url('${paper.images[0]}')`,
        backgroundSize: `${720 * (paper.zoomBasic / 100) * 1.3}px !important`
      }
    })]), _c('div', {
      staticClass: "contents"
    }, [_c('span', {
      staticClass: "number"
    }, [_vm._v(_vm._s(paper.code))]), _c('span', {
      staticClass: "name"
    }, [_vm._v(_vm._s(paper.name)), _c('small', [_vm._v(_vm._s(paper.nameEn))])])])]);
  }), 0)])])]), _c('div', {
    staticClass: "collection-section1"
  }, [_c('h2', {
    staticClass: "title"
  }, [_vm._v(" " + _vm._s(_vm.paper.name)), _c('span', [_vm._v(_vm._s(_vm.paper.nameEn))])]), _c('div', {
    staticClass: "assistant"
  }, [_c('wishlist-button', {
    attrs: {
      "product": _vm.paper
    }
  }), _c('div', {
    staticClass: "download"
  }, [_c('button', {
    on: {
      "click": _vm.downloadSample
    }
  }, [_vm._v("샘플이미지 다운로드")])]), _vm.paper.paperCollection.file ? _c('div', {
    staticClass: "download"
  }, [_c('button', {
    on: {
      "click": _vm.downloadCollection
    }
  }, [_vm._v("컬렉션 전체이미지 다운로드")])]) : _vm._e()], 1)]), _c('div', {
    staticClass: "collection-section2"
  }, [_c('div', {
    staticClass: "contents"
  }, [_c('div', {
    staticClass: "box1"
  }, [_c('vue-magnifier', {
    attrs: {
      "src": [_vm.paper.images[_vm.imageIndex]],
      "src-large": [_vm.paper.images[_vm.imageIndex]],
      "zoom": [_vm.paper.zoomBasic, _vm.paper.zoomHeight, 100][_vm.imageIndex]
    }
  })], 1), _c('div', {
    staticClass: "box2"
  }, [_c('div', {
    staticClass: "in_box",
    on: {
      "click": function ($event) {
        _vm.imageIndex = 0;
      }
    }
  }, [_c('p', {
    staticClass: "in_box_thumnail_title"
  }, [_vm._v("기본보기")]), _c('div', {
    style: {
      'background-image': `url('${_vm.paper.images[0]}')`,
      backgroundSize: `${_vm.paper.zoomBasic + 60}% !important`
    }
  })]), _c('div', {
    staticClass: "in_box",
    on: {
      "click": function ($event) {
        _vm.imageIndex = 1;
      }
    }
  }, [_c('p', {
    staticClass: "in_box_thumnail_title"
  }, [_vm._v("한폭보기")]), _c('div', {
    style: {
      'background-image': `url('${_vm.paper.images[1]}')`,
      backgroundSize: `${_vm.paper.zoomHeight + 10}% !important`
    }
  })]), _c('div', {
    staticClass: "in_box",
    on: {
      "click": function ($event) {
        _vm.imageIndex = 2;
      }
    }
  }, [_c('p', {
    staticClass: "in_box_thumnail_title"
  }, [_vm._v("간지보기")]), _c('div', {
    style: {
      'background-image': `url('${_vm.paper.images[2]}')`
    }
  })])])])]), _c('div', {
    staticStyle: {
      "position": "relative",
      "height": "0",
      "overflow": "hidden"
    }
  }, [_c('div', {
    ref: "sample",
    staticClass: "collection-download",
    staticStyle: {
      "position": "absolute"
    }
  }, [_c('div', {
    staticClass: "cd-head"
  }, [_c('div', {
    staticClass: "cd-title"
  }, [_c('div', {
    staticClass: "cd-tit-img",
    style: {
      backgroundImage: `url('${_vm.collection.thumb}')`
    }
  }), _c('h2', [_vm._v(_vm._s(_vm.collection.name))])]), _c('div', {
    staticClass: "cd-text"
  }, [_c('h3', {
    staticClass: "cd-name"
  }, [_vm._v(" " + _vm._s(_vm.paper.name)), _c('small', [_vm._v(_vm._s(_vm.paper.nameEn))])]), _c('div', {
    staticClass: "cd-num"
  }, [_vm._v(_vm._s(_vm.paper.code))])])]), _c('div', {
    staticClass: "cd-body"
  }, [_c('div', {
    staticClass: "cd-img-wrap"
  }, [_c('img', {
    attrs: {
      "src": _vm.paper.images[0],
      "alt": "이미지"
    }
  })])])])]), _c('div', {
    staticClass: "collection-section3"
  }, [_vm.paper.bestMatchsEnabled ? _c('div', {
    staticClass: "title"
  }, [_c('h3', [_vm._v("BEST MATCH")]), _c('div', {
    staticClass: "line"
  })]) : _vm._e(), _vm.paper.bestMatchsEnabled ? _c('div', {
    staticClass: "contents"
  }, _vm._l(_vm.paper.bestMatchs, function (bestMatch) {
    return _c('div', {
      staticClass: "box"
    }, _vm._l(bestMatch.products, function (product) {
      return _c('div', {
        staticClass: "in_box"
      }, [_c('div', {
        staticClass: "in_box-img",
        style: {
          backgroundImage: `url('${product.images[0]}')`
        }
      }), _c('h5', [_vm._v(_vm._s(product.nameEn))]), _c('h4', [_vm._v(_vm._s(product.name))]), _c('p', [_vm._v(_vm._s(product.code))])]);
    }), 0);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "contents2"
  }, [_c('div', {
    staticClass: "number"
  }, [_c('h4', [_vm._v(_vm._s(_vm.paper.code))])]), _c('div', {
    staticClass: "title"
  }, [_c('h5', [_vm._v(" " + _vm._s(_vm.paper.name) + " "), _c('span', {}, [_vm._v(_vm._s(_vm.paper.nameEn))])])]), _c('p', {
    staticClass: "text",
    domProps: {
      "innerHTML": _vm._s(_vm.paper.content)
    }
  }), _vm.paper.iconsEnabled ? _c('div', {
    staticClass: "right"
  }, _vm._l(_vm.paper.icons, function (icon) {
    return _c('div', {
      staticClass: "color"
    }, [_c('div', [_c('img', {
      attrs: {
        "src": icon.filename,
        "alt": ""
      }
    })]), _c('p', [_vm._v(_vm._s(icon.name))])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "tab"
  }, _vm._l(_vm.paper.hashtags, function (hashtag) {
    return _c('p', [_vm._v("#" + _vm._s(hashtag))]);
  }), 0), _c('div', {
    staticClass: "table"
  }, [_c('div', {
    staticClass: "box box1"
  }, [_c('div', {
    staticClass: "in_box"
  }, [_c('h5', [_vm._v("컬렉션")]), _c('p', [_vm._v(_vm._s(_vm.paper.paperCollection.name))])]), _c('div', {
    staticClass: "in_box"
  }, [_c('h5', [_vm._v("색상")]), _c('p', [_vm._v(_vm._s(_vm.paper.color))])])]), _c('div', {
    staticClass: "box box2"
  }, [_c('div', {
    staticClass: "in_box"
  }, [_c('h5', [_vm._v("스타일")]), _c('p', [_vm._v(_vm._s(_vm.paper.style))])]), _c('div', {
    staticClass: "in_box"
  }, [_c('h5', [_vm._v("사이즈")]), _c('p', [_vm._v(_vm._s(_vm.paper.size))])])]), _c('div', {
    staticClass: "box box3"
  }, [_c('div', {
    staticClass: "in_box"
  }, [_c('h5', [_vm._v("디자인")]), _c('p', [_vm._v(_vm._s(_vm.paper.design))])]), _c('div', {
    staticClass: "in_box"
  }, [_c('h5', [_vm._v("소재")]), _c('p', [_vm._v(_vm._s(_vm.paper.material))])])])]), _c('div', {
    staticClass: "line"
  })]), (_vm.inquires || []).length ? _c('div', {
    staticClass: "contents-board"
  }, [_vm._m(1), _c('div', {
    staticClass: "cb-body"
  }, [_c('div', {
    staticClass: "board-table board-table--detail",
    attrs: {
      "role": "table"
    }
  }, [_vm._m(2), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, _vm._l(_vm.inquires, function (board, index) {
    return _c('div', {
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      staticClass: "num",
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(parseInt(index) + 1))]), _c('div', {
      staticClass: "tit",
      attrs: {
        "role": "cell"
      }
    }, [_c('div', {
      staticClass: "title"
    }, [_c('a', {
      attrs: {
        "href": `/community/interior?_board=${board._id}`
      }
    }, [_vm._v(_vm._s(board.subject))])])]), _c('div', {
      staticClass: "writer",
      attrs: {
        "role": "cell"
      }
    }, [board.writer ? _c('span', [_vm._v(_vm._s(board.writer.name.substr(0, 2)) + _vm._s(board.writer.name.substr(2).replace(/./g, "*")))]) : _c('span', [_vm._v("탈퇴회원")])]), _c('div', {
      staticClass: "date",
      attrs: {
        "role": "cell"
      }
    }, [_c('span', {
      staticClass: "date"
    }, [_vm._v(_vm._s(board.createDate.toDate()))])])]);
  }), 0)])])]) : _vm._e(), (_vm.supports || []).length ? _c('div', {
    staticClass: "contents-board"
  }, [_vm._m(3), _c('div', {
    staticClass: "cb-body"
  }, [_c('div', {
    staticClass: "board-photo"
  }, [_c('ul', _vm._l(_vm.supports, function (board) {
    return _c('li', [_c('div', {
      staticClass: "photo-wrap"
    }, [_c('div', {
      staticClass: "photo-header"
    }, [_c('div', {
      staticClass: "photo-img"
    }, [_c('a', {
      style: {
        backgroundImage: `url('${board.thumb}')`
      },
      attrs: {
        "role": "link"
      }
    })])]), _c('div', {
      staticClass: "photo-body"
    }, [_c('a', {
      attrs: {
        "role": "link"
      }
    }, [_c('h2', {
      staticClass: "photo-title"
    }, [_vm._v(_vm._s(board.name))]), _c('p', {
      staticClass: "photo-text"
    }, [_vm._v(_vm._s(board.summary))])])])])]);
  }), 0)])])]) : _vm._e(), (_vm.shops || []).length ? _c('div', {
    staticClass: "contents-board"
  }, [_vm._m(4), _c('div', {
    staticClass: "cb-body"
  }, [_c('div', {
    staticClass: "board-table board-table--search",
    attrs: {
      "role": "table"
    }
  }, [_vm._m(5), _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, _vm._l(_vm.shops, function (board) {
    return _c('div', {
      attrs: {
        "role": "row"
      }
    }, [_c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(board.area))]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(board.storeName))]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(board.address1) + " " + _vm._s(board.address2))]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_vm._v(_vm._s(board.storePhone))]), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, _vm._l(board.collections, function (collection) {
      return _c('span', {
        staticClass: "label label-gray"
      }, [_vm._v(_vm._s(collection))]);
    }), 0), _c('div', {
      attrs: {
        "role": "cell"
      }
    }, [_c('a', {
      staticClass: "button button--round button--point button--search",
      attrs: {
        "role": "link",
        "href": `/shops/view/${board._id}`
      }
    }, [_c('i', {
      staticClass: "icon icon-search"
    }), _vm._v("상세정보")])])]);
  }), 0)])])]) : _vm._e(), _c('div', {
    staticClass: "contents-detail"
  }, [_c('div', {
    staticClass: "page-head"
  }, [_c('div', {
    staticClass: "page-head__inner page-head__img-wrap"
  }, [_c('div', {
    staticClass: "page-head__img",
    style: {
      backgroundImage: `url('${_vm.collection.thumb}')`
    }
  }), _c('div', {
    staticClass: "page-head__con"
  }, [_c('h1', {
    staticClass: "page-head__title2"
  }, [_vm._v(_vm._s(_vm.collection.name))]), _c('p', {
    staticClass: "page-head__text",
    domProps: {
      "innerHTML": _vm._s((_vm.collection.details || '').replace(/\n/g, '<br>'))
    }
  })])])]), _c('img', {
    attrs: {
      "src": "/images/sub/collection/detail_01.jpg",
      "alt": ""
    }
  }), _vm.collection.isEcoFriendly ? _c('img', {
    attrs: {
      "src": "/images/sub/collection/detail_02.jpg",
      "alt": ""
    }
  }) : _vm._e(), _c('img', {
    attrs: {
      "src": "/images/sub/collection/detail_03.jpg",
      "alt": ""
    }
  })])])]) : _vm._e()])])]), _c('main-footer')], 1) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pattern-toggle"
  }, [_c('button', {
    staticClass: "button"
  }, [_vm._v("패턴을 선택해주세요 "), _c('i', {
    staticClass: "icon icon-arrow-white"
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cb-header"
  }, [_c('h2', {
    staticClass: "cd-tit"
  }, [_vm._v("관련제품 인테리어 상담")]), _c('a', {
    staticClass: "cd-more",
    attrs: {
      "href": "/community/interior"
    }
  }, [_vm._v("more")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    staticClass: "num",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("번호")]), _c('div', {
    staticClass: "tit",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("제목")]), _c('div', {
    staticClass: "writer",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성자")]), _c('div', {
    staticClass: "date",
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("작성일")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cb-header"
  }, [_c('h2', {
    staticClass: "cd-tit"
  }, [_vm._v("관련제품 협찬정보")]), _c('a', {
    staticClass: "cd-more",
    attrs: {
      "href": "/community/sponsor"
    }
  }, [_vm._v("more")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "cb-header"
  }, [_c('h2', {
    staticClass: "cd-tit"
  }, [_vm._v("관련제품 취급 인테리어 샵 찾기")]), _c('a', {
    staticClass: "cd-more",
    attrs: {
      "href": "/shops/list"
    }
  }, [_vm._v("more")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "role": "rowgroup"
    }
  }, [_c('div', {
    attrs: {
      "role": "row"
    }
  }, [_c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("지역")]), _c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("취급점명")]), _c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("주소")]), _c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("연락처")]), _c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("취급컬렉션")]), _c('div', {
    attrs: {
      "role": "columnheader"
    }
  }, [_vm._v("상세정보")])])]);

}]

export { render, staticRenderFns }