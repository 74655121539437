var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "like"
  }, [!_vm.cart ? _c('button', {
    on: {
      "click": _vm.addToCart
    }
  }, [_vm._m(0), _c('span', [_vm._v("찜하기")])]) : _c('button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._m(1), _c('span', [_vm._v("찜하기")])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    attrs: {
      "src": "/images/sub/collection/h1.png",
      "alt": ""
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('img', {
    attrs: {
      "src": "/images/sub/collection/h2.png",
      "alt": ""
    }
  })]);

}]

export { render, staticRenderFns }