var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "vue-magnifier-container"
  }, [_vm._t("default"), _c('span', {
    ref: "magnificationElement",
    staticClass: "preview",
    style: {
      backgroundImage: `url('${_vm.src}')`,
      backgroundSize: `${_vm.zoom || 100}% auto`
    }
  }, [_c('span', {
    ref: "glass",
    staticClass: "magnifying-glass",
    style: _vm.glassStyle
  })])], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }