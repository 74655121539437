<template>
    <div v-if="ready" class="wrap wrap--sub">
        <!-- 헤더 -->
        <main-header :val="val"></main-header>

        <!-- 메인 -->
        <main class="main" role="main">
            <div class="collection-index-container">
                <div class="page-head">
                    <div class="page-head__inner page-head__img-wrap">
                        <div class="page-head__img" :style="{ backgroundImage: `url('${collection.thumb}')` }"></div>
                        <div class="page-head__con">
                            <h1 class="page-head__title2">{{ collection.name }}</h1>
                            <p class="page-head__text" v-html="collection.summary.replace(/\n/g, '<br>')"></p>
                        </div>
                        <!-- <h1 class="page-head__title">D&D COLLECTION INDEX</h1>
                        <ul class="breadcrumb">
                            <li class="breadcrumb__list">Home</li>
                            <li class="breadcrumb__list">컬렉션</li>
                            <li class="breadcrumb__list">국내</li>
                            <li class="breadcrumb__list">디앤디 컬렉션 인덱스</li>
                        </ul> -->
                    </div>
                </div>

                <div class="container">
                    <div class="nine-btn-wrap nine-btn-wrap--index" v-if="collection.name === '나인'">
                        <router-link to="/center/notice?_notification=658000b6f265e33f0402f5fa" target="_blank" class="nine-btn">나인벽지 취급점 안내</router-link>
                    </div>
                    <!-- 인덱스 슬라이드 -->
                    <!-- <div class="index-slide-container">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                <div class="container"> -->
                    <div class="index-container">
                        <div class="index-wrapper">
                            <div v-for="texture in collection.children" class="index-items">
                                <div
                                    role="button"
                                    class="index-slide"
                                    :class="{ 'index-slide--selected': $data.texture == texture }"
                                    @click="
                                        $data.texture = texture;
                                        $data.textureSticky = texture;
                                    "
                                >
                                    <span class="thumbnail" :style="{ backgroundImage: `url('${texture.thumb}')` }"></span>
                                    <span class="name">{{ texture.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="button-prev">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="34px" height="17px">
                            <path fill-rule="evenodd" stroke-width="3px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M31.500,10.606 L9.500,10.606 L15.500,4.500 " />
                        </svg>
                    </div>
                    <div class="button-next">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="34px" height="16px">
                            <path fill-rule="evenodd" stroke-width="3px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M1.500,10.500 L23.500,10.500 L17.500,4.500 " />
                        </svg>
                    </div>
                    <div class="slide-pagination"></div>
                </div> -->
                    <!-- 벽지 슬라이드 -->
                    <div class="curtain-slide-container">
                        <a v-for="paper in papers" class="curtain-slide" :class="{ 'curtain-slide--hover': $data.paper.code == paper.code, 'curtain-slide--light': paper.fontType == 'light', 'curtain-slide--shadow': paper.fontType == 'shadow' }" @click="$data.paper = paper">
                            <div class="pattern">
                                <span class="background" :style="{ backgroundImage: `url('${paper.images[0]}')`, backgroundSize: `${720 * (paper.zoomBasic / 100) * 1.3}px !important` }"></span>
                            </div>
                            <div class="contents">
                                <span class="number">{{ paper.code }}</span>
                                <span class="name"
                                    >{{ paper.name }}<small>{{ paper.nameEn }}</small></span
                                >
                            </div>
                        </a>
                    </div>
                    <!-- 벽지 상세 컨텐츠 -->
                    <div v-if="paper" class="collection-view-container" data-collection="opened">
                        <!-- 상단 벽지 비주얼 -->
                        <div class="wallpaper-container" style="height:auto;">
                            <div class="wallpaper-inner">
                                <div class="wallpaper-pattern">
                                    <!-- 패턴 토글 -->
                                    <div class="pattern-toggle">
                                        <button class="button">패턴을 선택해주세요 <i class="icon icon-arrow-white"></i></button>
                                    </div>
                                    <!-- 패턴 슬라이드 -->
                                    <div class="pattern-slide-container">
                                        <div class="swiper-container">
                                            <div class="swiper-wrapper">
                                                <div v-for="texture in collection.children" class="swiper-slide">
                                                    <div role="button" class="pattern-slide" :class="{ 'pattern-slide--selected': $data.texture == texture }" @click="$data.texture = texture">
                                                        <span class="thumbnail" :style="{ backgroundImage: `url('${texture.thumb}')` }"></span>
                                                        <span class="name">{{ texture.name }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="button-prev">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.5px" height="26.5px">
                                                <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="3px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M14.500,20.500 L6.500,12.605 L14.500,4.500 " />
                                            </svg>
                                        </div>
                                        <div class="button-next">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="17.5px" height="26.5px">
                                                <path fill-rule="evenodd" stroke="rgb(255, 255, 255)" stroke-width="3px" stroke-linecap="butt" stroke-linejoin="miter" fill="none" d="M1.500,20.500 L9.500,12.500 L1.500,4.500 " />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                                <div class="wallpaper-visual">
                                    <div class="wallpaper-slide-container">
                                        <a v-for="(paper, index) in papers" class="wallpaper-slide" :class="{ 'wallpaper-slide--hover': index == 0, 'wallpaper-slide--light': paper.fontType == 'light', 'wallpaper-slide--shadow': paper.fontType == 'shadow' }" @click="$data.paper = paper">
                                            <div class="pattern">
                                                <span class="background" :style="{ backgroundImage: `url('${paper.images[0]}')`, backgroundSize: `${720 * (paper.zoomBasic / 100) * 1.3}px !important` }"></span>
                                            </div>
                                            <div class="contents">
                                                <span class="number">{{ paper.code }}</span>
                                                <span class="name"
                                                    >{{ paper.name }}<small>{{ paper.nameEn }}</small></span
                                                >
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 상세내용 -->
                        <div class="collection-section1">
                            <h2 class="title">
                                {{ paper.name }}<span>{{ paper.nameEn }}</span>
                            </h2>
                            <div class="assistant">
                                <!-- 찜하기 버튼 -->
                                <wishlist-button :product="paper"></wishlist-button>
                                <div class="download">
                                    <button @click="downloadSample">샘플이미지 다운로드</button>
                                </div>
                                <div v-if="paper.paperCollection.file" class="download">
                                    <button @click="downloadCollection">컬렉션 전체이미지 다운로드</button>
                                </div>
                            </div>
                        </div>
                        <div class="collection-section2">
                            <div class="contents">
                                <div class="box1">
                                    <vue-magnifier :src="[paper.images[imageIndex]]" :src-large="[paper.images[imageIndex]]" :zoom="[paper.zoomBasic, paper.zoomHeight, 100][imageIndex]" />
                                </div>
                                <!-- <div class="box1"><div :style="{ 'background-image': image ? `url('${image}')` : `url('${paper.images[0]}')` }"></div></div> -->
                                <div class="box2">
                                    <div class="in_box" @click="imageIndex = 0">
                                        <p class="in_box_thumnail_title">기본보기</p>
                                        <div :style="{ 'background-image': `url('${paper.images[0]}')`, backgroundSize: `${paper.zoomBasic + 60}% !important` }"></div>
                                    </div>
                                    <div class="in_box" @click="imageIndex = 1">
                                        <p class="in_box_thumnail_title">한폭보기</p>
                                        <div :style="{ 'background-image': `url('${paper.images[1]}')`, backgroundSize: `${paper.zoomHeight + 10}% !important` }"></div>
                                    </div>
                                    <div class="in_box" @click="imageIndex = 2">
                                        <p class="in_box_thumnail_title">간지보기</p>
                                        <div :style="{ 'background-image': `url('${paper.images[2]}')` }"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="position: relative; height: 0; overflow: hidden;">
                            <div ref="sample" class="collection-download" style="position: absolute">
                                <div class="cd-head">
                                    <div class="cd-title">
                                        <div class="cd-tit-img" :style="{ backgroundImage: `url('${collection.thumb}')` }"></div>
                                        <h2>{{ collection.name }}</h2>
                                    </div>
                                    <div class="cd-text">
                                        <h3 class="cd-name">
                                            {{ paper.name }}<small>{{ paper.nameEn }}</small>
                                        </h3>
                                        <div class="cd-num">{{ paper.code }}</div>
                                    </div>
                                </div>
                                <div class="cd-body">
                                    <div class="cd-img-wrap">
                                        <img :src="paper.images[0]" alt="이미지" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="collection-section3">
                            <div v-if="paper.bestMatchsEnabled" class="title">
                                <h3>BEST MATCH</h3>
                                <div class="line"></div>
                            </div>
                            <div v-if="paper.bestMatchsEnabled" class="contents">
                                <div v-for="bestMatch in paper.bestMatchs" class="box">
                                    <div v-for="product in bestMatch.products" class="in_box">
                                        <div class="in_box-img" :style="{ backgroundImage: `url('${product.images[0]}')` }"></div>
                                        <h5>{{ product.nameEn }}</h5>
                                        <h4>{{ product.name }}</h4>
                                        <p>{{ product.code }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="contents2">
                                <div class="number">
                                    <h4>{{ paper.code }}</h4>
                                </div>
                                <div class="title">
                                    <h5>
                                        {{ paper.name }} <span class="">{{ paper.nameEn }}</span>
                                    </h5>
                                </div>
                                <p class="text" v-html="paper.content"></p>
                                <div v-if="paper.iconsEnabled" class="right">
                                    <div v-for="icon in paper.icons" class="color">
                                        <div><img :src="icon.filename" alt="" /></div>
                                        <p>{{ icon.name }}</p>
                                    </div>
                                </div>
                                <div class="tab">
                                    <p v-for="hashtag in paper.hashtags">#{{ hashtag }}</p>
                                </div>
                                <div class="table">
                                    <div class="box box1">
                                        <div class="in_box">
                                            <h5>컬렉션</h5>
                                            <p>{{ paper.paperCollection.name }}</p>
                                        </div>
                                        <div class="in_box">
                                            <h5>색상</h5>
                                            <p>{{ paper.color }}</p>
                                        </div>
                                    </div>
                                    <div class="box box2">
                                        <div class="in_box">
                                            <h5>스타일</h5>
                                            <p>{{ paper.style }}</p>
                                        </div>
                                        <div class="in_box">
                                            <h5>사이즈</h5>
                                            <p>{{ paper.size }}</p>
                                        </div>
                                    </div>
                                    <div class="box box3">
                                        <div class="in_box">
                                            <h5>디자인</h5>
                                            <p>{{ paper.design }}</p>
                                        </div>
                                        <div class="in_box">
                                            <h5>소재</h5>
                                            <p>{{ paper.material }}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="line"></div>

                                <!-- <div class="container">
                                        대리점보기
                                    </div>
                                    <div class="container">
                                        게시판1
                                    </div>
                                    <div class="container">
                                        게시판2
                                    </div>
                                    <div class="container">
                                        친환경
                                    </div>
                                    <div class="container">
                                        책자소개
                                    </div>
                                    <div class="container">
                                        회사소개
                                    </div> -->
                            </div>

                            <div v-if="(inquires || []).length" class="contents-board">
                                <div class="cb-header">
                                    <h2 class="cd-tit">관련제품 인테리어 상담</h2>
                                    <a href="/community/interior" class="cd-more">more</a>
                                </div>
                                <div class="cb-body">
                                    <div role="table" class="board-table board-table--detail">
                                        <div role="rowgroup">
                                            <div role="row">
                                                <div role="columnheader" class="num">번호</div>
                                                <div role="columnheader" class="tit">제목</div>
                                                <div role="columnheader" class="writer">작성자</div>
                                                <div role="columnheader" class="date">작성일</div>
                                            </div>
                                        </div>
                                        <div role="rowgroup">
                                            <div v-for="(board, index) in inquires" role="row">
                                                <div role="cell" class="num">{{ parseInt(index) + 1 }}</div>
                                                <div role="cell" class="tit">
                                                    <div class="title">
                                                        <a :href="`/community/interior?_board=${board._id}`">{{ board.subject }}</a>
                                                    </div>
                                                </div>
                                                <div role="cell" class="writer">
                                                    <span v-if="board.writer">{{ board.writer.name.substr(0, 2) }}{{ board.writer.name.substr(2).replace(/./g, "*") }}</span>
                                                    <span v-else>탈퇴회원</span>
                                                </div>
                                                <div role="cell" class="date">
                                                    <span class="date">{{ board.createDate.toDate() }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="(supports || []).length" class="contents-board">
                                <div class="cb-header">
                                    <h2 class="cd-tit">관련제품 협찬정보</h2>
                                    <a href="/community/sponsor" class="cd-more">more</a>
                                </div>
                                <div class="cb-body">
                                    <div class="board-photo">
                                        <ul>
                                            <li v-for="board in supports">
                                                <div class="photo-wrap">
                                                    <div class="photo-header">
                                                        <div class="photo-img">
                                                            <a role="link" :style="{ backgroundImage: `url('${board.thumb}')` }"></a>
                                                        </div>
                                                    </div>
                                                    <div class="photo-body">
                                                        <a role="link">
                                                            <h2 class="photo-title">{{ board.name }}</h2>
                                                            <p class="photo-text">{{ board.summary }}</p>
                                                        </a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div v-if="(shops || []).length" class="contents-board">
                                <div class="cb-header">
                                    <h2 class="cd-tit">관련제품 취급 인테리어 샵 찾기</h2>
                                    <a href="/shops/list" class="cd-more">more</a>
                                </div>
                                <div class="cb-body">
                                    <div role="table" class="board-table board-table--search">
                                        <div role="rowgroup">
                                            <div role="row">
                                                <div role="columnheader">지역</div>
                                                <div role="columnheader">취급점명</div>
                                                <div role="columnheader">주소</div>
                                                <div role="columnheader">연락처</div>
                                                <div role="columnheader">취급컬렉션</div>
                                                <div role="columnheader">상세정보</div>
                                            </div>
                                        </div>
                                        <div role="rowgroup">
                                            <div v-for="board in shops" role="row">
                                                <div role="cell">{{ board.area }}</div>
                                                <div role="cell">{{ board.storeName }}</div>
                                                <div role="cell">{{ board.address1 }} {{ board.address2 }}</div>
                                                <div role="cell">{{ board.storePhone }}</div>
                                                <div role="cell">
                                                    <span v-for="collection in board.collections" class="label label-gray">{{ collection }}</span>
                                                </div>
                                                <div role="cell">
                                                    <a role="link" :href="`/shops/view/${board._id}`" class="button button--round button--point button--search"><i class="icon icon-search"></i>상세정보</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="contents-detail">
                                <div class="page-head">
                                    <div class="page-head__inner page-head__img-wrap">
                                        <div class="page-head__img" :style="{ backgroundImage: `url('${collection.thumb}')` }"></div>
                                        <div class="page-head__con">
                                            <h1 class="page-head__title2">{{ collection.name }}</h1>
                                            <p class="page-head__text" v-html="(collection.details || '').replace(/\n/g, '<br>')"></p>
                                        </div>
                                    </div>
                                </div>

                                <img src="/images/sub/collection/detail_01.jpg" alt="" />
                                <img v-if="collection.isEcoFriendly" src="/images/sub/collection/detail_02.jpg" alt="" />
                                <img src="/images/sub/collection/detail_03.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <!-- 푸터 -->
        <main-footer></main-footer>
    </div>
</template>

<script>
import download from "downloadjs";
import * as htmlToImage from "html-to-image";
import VueMagnifier from "@/components/plugins/vue-magnifier/vue-magnifier.vue";
import MainHeader from "@/components/client/inc/main-header.vue";
import MainFooter from "@/components/client/inc/main-footer.vue";

import WishlistButton from "@/components/client/collection/wishlist-button.vue";

export default {
    components: {
        VueMagnifier,
        MainHeader,
        MainFooter,
        WishlistButton,
    },
    data: () => {
        return {
            val: "wallpaper",

            ready: false,

            collection: null,
            texture: null,
            // textureSticky: null,
            papers: [],
            // papersSticky: [],
            paper: null,

            inquires: [],
            supports: [],
            shops: [],

            imageIndex: 0,
        };
    },
    created() {},
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var { categories } = await this.getCollection(this.$route.params.code);
                this.collection = categories[0];

                if (this.$route.query.code) {
                    var { product } = await this.getPaper(this.$route.query.code);
                    var { products } = await this.getPapers(product._category);

                    this.papers = products;
                    this.texture = this.collection.children.find((texture) => texture._id == product._category);
                    this.paper = this.papers.find((paper) => paper.code == product.code);
                } else {
                    this.texture = this.collection.children[0] || null;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
            // var slide = new Swiper ('.index-slide-container .swiper-container', {
            //     slidesPerView: 8,
            //     spaceBetween: 30,
            //     // autoplay: {
            //     //     delay: 3000,
            //     //     disableOnInteraction: false,
            //     // },
            //     speed: 500,
            //     loop: false,
            //     // pagination: {
            //     //     el: '.index-slide-container .slide-pagination',
            //     //     clickable: true,
            //     // },
            //     navigation: {
            //         nextEl: '.index-slide-container .button-next',
            //         prevEl: '.index-slide-container .button-prev'
            //     },
            // });

            // 인덱스 슬라이드
            // (function(){
            //     let button = document.querySelectorAll('.index-slide-container .index-slide');
            //
            //     for(let i = 0; i < button.length; i++){
            //         button[i].addEventListener('click', selectCollection);
            //     }
            //
            //     function selectCollection(){
            //         if(this.classList.contains('index-slide--selected')){
            //             return;
            //         }else{
            //             for(let i = 0; i < button.length; i++){
            //                 button[i].classList.remove('index-slide--selected');
            //             }
            //             this.classList.add('index-slide--selected');
            //         }
            //     }
            // })();

            // var slide = new Swiper('.pattern-slide-container .swiper-container', {
            //     slidesPerView: 8,
            //     spaceBetween: 40,
            //     // autoplay: {
            //     //     delay: 3000,
            //     //     disableOnInteraction: false,
            //     // },
            //     speed: 500,
            //     loop: false,
            //     // pagination: {
            //     //     el: '.pattern-slide-container .slide-pagination',
            //     //     clickable: true,
            //     // },
            //     navigation: {
            //         nextEl: '.pattern-slide-container .button-next',
            //         prevEl: '.pattern-slide-container .button-prev'
            //     },
            // });
            //
            //
            // // 패턴 슬라이드
            // (function() {
            //     let button = document.querySelectorAll('.pattern-slide-container .pattern-slide');
            //
            //     for (let i = 0; i < button.length; i++) {
            //         button[i].addEventListener('click', selectCollection);
            //     }
            //
            //     function selectCollection() {
            //         if (this.classList.contains('pattern-slide--selected')) {
            //             return;
            //         } else {
            //             for (let i = 0; i < button.length; i++) {
            //                 button[i].classList.remove('pattern-slide--selected');
            //             }
            //             this.classList.add('pattern-slide--selected');
            //         }
            //     }
            // })();
            //
            // // 벽지 슬라이드
            // (function() {
            //     let curtain = document.querySelectorAll('.wallpaper-slide');
            //     for (let i = 0; i < curtain.length; i++) {
            //         curtain[i].addEventListener('mouseenter', spreading);
            //     }
            //
            //     function spreading() {
            //         for (let i = 0; i < curtain.length; i++) {
            //             curtain[i].classList.remove('wallpaper-slide--hover');
            //         }
            //         this.classList.add('wallpaper-slide--hover');
            //     }
            // })();
            //
            // //  벽지 Sticky
            // (function() {
            //     var wallpaper = document.querySelector('.wallpaper-container'),
            //         h = document.querySelector('.header').clientHeight;
            //
            //     window.addEventListener('scroll', scrollWallpaper);
            //
            //     function scrollWallpaper() {
            //         var y = window.pageYOffset;
            //
            //         if (y > h) {
            //             wallpaper.setAttribute('data-wallpaper', 'sticky');
            //         } else {
            //             wallpaper.removeAttribute('data-wallpaper');
            //         }
            //     }
            // })();
            //
            // // 벽지 슬라이드
            // (function() {
            //     let curtain = document.querySelectorAll('.curtain-slide');
            //     for (let i = 0; i < curtain.length; i++) {
            //         curtain[i].addEventListener('mouseenter', spreading);
            //     }
            //
            //     function spreading() {
            //         for (let i = 0; i < curtain.length; i++) {
            //             curtain[i].classList.remove('curtain-slide--hover');
            //         }
            //         this.classList.add('curtain-slide--hover');
            //     }
            // })();
            //
            // // 상세 컨텐츠 & 벽지 애니메이션
            // (function() {
            //     const curtain = document.querySelectorAll('.curtain-slide'),
            //         view = document.querySelector('.collection-view-container'),
            //         wallpaper = document.querySelector('.wallpaper-container');
            //
            //     curtain.forEach(function(e) {
            //         e.addEventListener('click', showDetailContents);
            //     });
            //
            //     function showDetailContents() {
            //         view.style.display = 'block';
            //
            //         let y = view.offsetTop;
            //         let height = document.querySelector('.curtain-slide-container').clientHeight;
            //
            //         y = y + height - 100;
            //
            //         view.setAttribute('data-collection', 'opened');
            //
            //         console.log(y);
            //
            //         window.scrollTo(0, y);
            //     }
            // })();
        },

        toPng() {
            return htmlToImage.toPng(this.$refs.sample);
        },

        downloadSample() {
            this.toPng().then((dataUrl) => {
                download(dataUrl, "sample.png");
            });
        },

        downloadCollection() {
            download(this.paper.paperCollection.file, this.paper.paperCollection.file.split("/").shift());
        },

        getCollection(code) {
            return this.$http.get("/api/v1/shop/categories", { params: { code } }).then((res) => res.data);
        },

        getPapers(_texture) {
            return this.$http.get("/api/v1/shop/products", { params: { _category: _texture } }).then((res) => res.data);
        },

        getPaper(code) {
            return this.$http.get("/api/v1/shop/products", { params: { code } }).then((res) => {
                return { product: res.data.products[0] || undefined };
            });
        },

        getBoards({ code, limit, relativeProducts }) {
            return this.$http.get("/api/v1/boards", { header: { skip: 0, limit }, params: { code, relativeProducts } }).then((res) => res.data);
        },

        getResource(filename) {
            return this.$http({
                url: filename,
                method: "GET",
                responseType: "blob",
            }).then((result) => new File([result.data], filename));
        },
    },
    watch: {
        async $route() {
            try {
                var { categories } = await this.getCollection(this.$route.params.code);
                this.collection = categories[0];

                if (this.$route.query.code) {
                    var { product } = await this.getPaper(this.$route.query.code);
                    var { products } = await this.getPapers(product._category);

                    this.papers = products;
                    this.texture = this.collection.children.find((texture) => texture._id == product._category);
                    this.paper = this.papers.find((paper) => paper.code == product.code);
                } else {
                    this.texture = this.collection.children[0] || null;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        texture() {
            if (!this.texture) {
                this.papers = [];
                this.paper = null;
                return;
            }

            this.getPapers(this.texture._id).then(({ products }) => {
                this.papers = products;
                this.paper = this.papers[0] || null;

                this.$nextTick(() => {
                    var slide = new Swiper(".pattern-slide-container .swiper-container", {
                        slidesPerView: 12,
                        spaceBetween: 0,
                        // autoplay: {
                        //     delay: 3000,
                        //     disableOnInteraction: false,
                        // },
                        speed: 500,
                        loop: false,
                        // pagination: {
                        //     el: '.pattern-slide-container .slide-pagination',
                        //     clickable: true,
                        // },
                        navigation: {
                            nextEl: ".pattern-slide-container .button-next",
                            prevEl: ".pattern-slide-container .button-prev",
                        },
                        breakpoints: {
                            1024: {
                                slidesPerView: 10,
                            },
                            768: {
                                slidesPerView: 8,
                            },
                            576: {
                                slidesPerView: 6,
                            },
                            375: {
                                slidesPerView: 4,
                            },
                        },
                    });

                    // 패턴 슬라이드
                    (function() {
                        let button = document.querySelectorAll(".pattern-slide-container .pattern-slide");

                        for (let i = 0; i < button.length; i++) {
                            button[i].addEventListener("click", selectCollection);
                        }

                        function selectCollection() {
                            if (this.classList.contains("pattern-slide--selected")) {
                                return;
                            } else {
                                for (let i = 0; i < button.length; i++) {
                                    button[i].classList.remove("pattern-slide--selected");
                                }
                                this.classList.add("pattern-slide--selected");
                            }
                        }
                    })();

                    // 벽지 슬라이드
                    (function() {
                        let curtain = document.querySelectorAll(".wallpaper-slide");
                        for (let i = 0; i < curtain.length; i++) {
                            curtain[i].addEventListener("mouseenter", spreading);
                        }

                        function spreading() {
                            for (let i = 0; i < curtain.length; i++) {
                                curtain[i].classList.remove("wallpaper-slide--hover");
                            }
                            this.classList.add("wallpaper-slide--hover");
                        }
                    })();

                    //  벽지 Sticky
                    (function() {
                        var wallpaper = document.querySelector(".wallpaper-container"),
                            h = window.pageYOffset + document.querySelector(".collection-section1").getBoundingClientRect().top;

                        window.addEventListener("scroll", scrollWallpaper);

                        function scrollWallpaper() {
                            var y = window.pageYOffset;

                            if (y > h) {
                                wallpaper.setAttribute("data-wallpaper", "sticky");
                            } else {
                                wallpaper.removeAttribute("data-wallpaper");
                            }
                        }
                    })();

                    // 벽지 슬라이드
                    (function() {
                        let curtain = document.querySelectorAll(".curtain-slide");
                        for (let i = 0; i < curtain.length; i++) {
                            curtain[i].addEventListener("mouseenter", spreading);
                        }

                        function spreading() {
                            for (let i = 0; i < curtain.length; i++) {
                                curtain[i].classList.remove("curtain-slide--hover");
                            }
                            this.classList.add("curtain-slide--hover");
                        }
                    })();

                    // 상세 컨텐츠 & 벽지 애니메이션
                    (function() {
                        const curtain = document.querySelectorAll(".curtain-slide"),
                            view = document.querySelector(".collection-view-container"),
                            wallpaper = document.querySelector(".wallpaper-container");

                        curtain.forEach(function(e) {
                            e.addEventListener("click", showDetailContents);
                        });

                        function showDetailContents() {
                            view.style.display = "block";

                            let y = view.offsetTop;
                            let height = document.querySelector(".curtain-slide-container").clientHeight;

                            y = y + height - 100;

                            view.setAttribute("data-collection", "opened");

                            console.log(y);

                            window.scrollTo(0, y);
                        }
                    })();

                    // 벽지 슬라이드
                    (function() {
                        let curtain = document.querySelectorAll(".wallpaper-slide");
                        for (let i = 0; i < curtain.length; i++) {
                            curtain[i].addEventListener("mouseenter", spreading);
                        }

                        function spreading() {
                            for (let i = 0; i < curtain.length; i++) {
                                curtain[i].classList.remove("wallpaper-slide--hover");
                            }
                            this.classList.add("wallpaper-slide--hover");
                        }
                    })();

                    //  벽지 Sticky
                    (function() {
                        var wallpaper = document.querySelector(".wallpaper-container"),
                            h = window.pageYOffset + document.querySelector(".collection-section1").getBoundingClientRect().top;

                        window.addEventListener("scroll", scrollWallpaper);

                        function scrollWallpaper() {
                            var y = window.pageYOffset;

                            if (y > h) {
                                wallpaper.setAttribute("data-wallpaper", "sticky");
                            } else {
                                wallpaper.removeAttribute("data-wallpaper");
                            }
                        }
                    })();

                    // 패턴 Toggle
                    (function() {
                        const button = document.querySelector(".wallpaper-pattern");

                        if (window.outerWidth > 1200) {
                            button.addEventListener("mouseover", isOver);
                            button.addEventListener("mouseout", isOut);
                        } else {
                            button.addEventListener("click", function() {
                                if (this.classList.contains("on")) {
                                    isOut();
                                } else {
                                    isOver();
                                }
                            });
                        }

                        function isOver() {
                            button.classList.add("on");
                        }
                        function isOut() {
                            button.classList.remove("on");
                        }
                    })();
                });
            });
        },
        // textureSticky(){
        //     if(!this.textureSticky){
        //         this.papers = [];
        //         this.paper = null;
        //         return;
        //     };
        //     this.texture = this.textureSticky;
        //     this.getPapers(this.textureSticky._id).then((papers) => {
        //         this.papersSticky = papers;

        //         this.$nextTick(() => {

        //         })
        //     });
        // },
        paper() {
            this.inquires = [];
            this.supports = [];
            this.shops = [];
            if (this.paper && this.paper._id) {
                this.imageIndex = 0;
                this.getBoards({ code: "interior-inquire", limit: 5, relativeProducts: this.paper._id }).then(({ boards }) => (this.inquires = boards));
                this.getBoards({ code: "support", limit: 3, relativeProducts: this.paper._id }).then(({ boards }) => (this.supports = boards));
                this.getBoards({ code: "interior-shop", limit: 5, relativeProducts: this.paper._id }).then(({ boards }) => (this.shops = boards));
            }
        },
    },
};
</script>
